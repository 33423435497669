import { OuterWrapper } from "./styles";

import Product from "components/Product/Product";
import { useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";

const baseUrl = window._env_.REACT_APP_QDAK_PRODUCT_URL;

const Products = ({
  shopId,
  group,
  path,
  id,
  products,
  qdakAccessKey,
  pageSize,
  setImageCounter,
  imageCounter,
}) => {
  const [error, setError] = useState(false);

  const [extProducts, setExtProducts] = useState();

  const createExtendedProducts = async () => {
    const extendedProducts = [];
    await products?.map((product, idx) => {
      let extendedProduct = {
        ...product,
        loaded: false,
        alt: `${product.id}_preview`,
        src:
          qdakAccessKey === null
            ? `${baseUrl}/shops/${shopId}/products/${product.id}/thumbnail`
            : `${baseUrl}/shops/${shopId}/products/${product.id}/thumbnail?accessKey=${qdakAccessKey}`,
      };
      extendedProducts.push(extendedProduct);
    });
    try {
      setExtProducts(extendedProducts);
    } catch (e) {}
  };

  useEffect(() => {
    createExtendedProducts();
  }, [products]);

  return (
    <OuterWrapper>
      {error && (
        <Container align="center">
          <Typography
            color="error"
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            A képek betöltése során hiba történt...
          </Typography>
          <Typography
            color="error"
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Amennyiben nem látja kívánt képet,
          </Typography>
          <Typography
            color="error"
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            kérjük vegye fel a kapcsolatot fényképészével.
          </Typography>
        </Container>
      )}
      {extProducts &&
        extProducts.map((product) => {
          return (
            <Product
              setError={setError}
              product={product}
              key={product.id}
              shopId={shopId}
              group={group}
              qdakAccessKey={qdakAccessKey}
              setImageCounter={setImageCounter}
              imageCounter={imageCounter}
            />
          );
        })}
    </OuterWrapper>
  );
};

export default Products;
