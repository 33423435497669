/** @format */

import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Breadcrumb } from "components/Breadcrumb/Breadcrumb";

import Products from "./Products/Products";
import background from "assets/images/test.jpg";
import defaultImage from "assets/images/test.jpg";
import GroupCard from "components/Group/GroupCard/GroupCard";
import {
  useStyles,
  ProductsWrapper,
  HomeWrapper,
  IntroSection,
  BackgroundWrapper,
  BackgroundImage,
  TitleContainer,
  Title,
  GroupsWrapper,
  BreadcrumbWrapper,
} from "./styles";
import { useGroupAPI } from "./hooks/useGroupAPI";
import {
  TablePagination,
  useTheme,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useGetQueryString } from "hooks/useGetQueryString";
import { useSelector } from "react-redux";
import LoadIndicator from "components/LoadIndicator/LoadIndicator";
import sizes from "constants/sizes";
import ShopNotificationDialog from "components/ShopNotificationDialog/ShopNotificationDialog";
import { Helmet } from "react-helmet";

const Group = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);

  const desktop = useMediaQuery(`(min-width:${sizes.SM.toString()}px)`);

  const paramAccessKey = useLocation().search.split("=")[1];

  const { idAndAccessKey } = useParams();

  const location = useLocation();

  const [id, setId] = useState(null);
  const [urlAccessKey, setUrlAccesskey] = useState(null);

  const [imageCounter, setImageCounter] = useState(0);

  const shopId = useSelector(
    (state) => state.nonPersistedReducers?.shopSliceReducer?.shopId
  );

  useEffect(() => {
    if (idAndAccessKey) {
      const [id, acString] = idAndAccessKey.split("&");
      setId(id);
      if (acString) {
        const accessKeyValue = acString.split("=")[1];
        setUrlAccesskey(accessKeyValue);
      }
    }
  }, [idAndAccessKey]);

  const {
    fetchHeader,
    header,
    headerLoading,
    fetchGroups,
    groups,
    loading,
    fetchProducts,
    products,
    productsLength,
    fetchCover,
    cover,
  } = useGroupAPI();

  useEffect(() => {
    setCurrentPage(1);
    setPageSize(10);
    window.scrollTo(0, 0);
    document.body.style.overflowY = "visible";
  }, [header?.id]);

  const getValidUntilFields = (modifiers) => {
    let validUntilP = "Lejárt";
    let validUntilD = "Lejárt";
    let validUntilPr = "Nincs";
    if (
      header?.productGroupModifierValidities?.find(
        (el) =>
          modifiers?.find(
            (m) =>
              m.priority === 1 &&
              (m.modifierUriSegment.includes("lelkesprint") ||
                m.modifierUriSegment.includes("paper")) &&
              !m.name.includes("Ajándék tárgy")
          )?.modifierUriSegment === el.modifierUriSegment
      )
    ) {
      validUntilP = header?.productGroupModifierValidities
        ?.find(
          (el) =>
            modifiers?.find(
              (m) =>
                m.priority === 1 &&
                (m.modifierUriSegment.includes("lelkesprint") ||
                  m.modifierUriSegment.includes("paper"))
            )?.modifierUriSegment === el.modifierUriSegment
        )
        .validUntil.substring(0, 10);
    }
    if (
      header?.productGroupModifierValidities?.find(
        (el) =>
          modifiers?.find(
            (m) => m.priority === 1 && m.modifierUriSegment.includes("email")
          )?.modifierUriSegment === el.modifierUriSegment
      )
    ) {
      validUntilD = header?.productGroupModifierValidities
        ?.find(
          (el) =>
            modifiers?.find(
              (m) => m.priority === 1 && m.modifierUriSegment.includes("email")
            )?.modifierUriSegment === el.modifierUriSegment
        )
        .validUntil.substring(0, 10);
    }
    if (
      header?.productGroupModifierValidities?.find(
        (el) =>
          modifiers?.find(
            (m) => m.priority === 1 && m.name.includes("Ajándék tárgy")
          )?.modifierUriSegment === el.modifierUriSegment
      )
    ) {
      validUntilPr = "Lejárt";
      validUntilPr = header?.productGroupModifierValidities
        ?.find(
          (el) =>
            modifiers?.find(
              (m) =>
                m.priority === 1 &&
                m.modifierUriSegment.includes("lelkesprint") &&
                m.name.includes("Ajándék tárgy")
            )?.modifierUriSegment === el.modifierUriSegment
        )
        .validUntil.substring(0, 10);
    }
    return (
      <>
        <Typography className={classes.validUntil}>
          Nyomtatott képek rendelési határideje:{" "}
          <label
            style={{
              color: theme.palette.primary.backgroundSecondary,
            }}
          >
            {validUntilP}
          </label>
        </Typography>
        <Typography className={classes.validUntil}>
          Digitális képek rendelési határideje:{" "}
          <label
            style={{
              color: theme.palette.primary.backgroundSecondary,
            }}
          >
            {validUntilD}
          </label>
        </Typography>
        {validUntilPr === "Nincs" ? (
          ""
        ) : (
          <Typography className={classes.validUntil}>
            Ajándéktárgyak rendelési határideje:{" "}
            <label
              style={{
                color: theme.palette.primary.backgroundSecondary,
              }}
            >
              {validUntilPr}
            </label>
          </Typography>
        )}
      </>
    );
  };

  const [stickyBreadcrumb, setStickyBreadcrumb] = useState(false);

  const changeBreadcrumb = () => {
    if (window.scrollY > 80) {
      setStickyBreadcrumb(true);
    }
    if (window.scrollY < 80) {
      setStickyBreadcrumb(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBreadcrumb);
    return () => window.removeEventListener("scroll", changeBreadcrumb);
  }, []);

  const { currentPage, setCurrentPage, pageSize, setPageSize, getQueryString } =
    useGetQueryString();

  const handleChangePage = (event, newPage) => {
    setImageCounter(0);
    window.scrollTo(0, 700);
    if (desktop) {
      window.scrollTo(0, 500);
    }

    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    window.scrollTo(0, 700);
    if (desktop) {
      window.scrollTo(0, 500);
    }
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const keys = useSelector(
    (state) => state.nonPersistedReducers.accessKeySliceReducer.accessKeys
  );

  const getQdakAccessKey = () => {
    const loadedAccessKey = keys[id];
    const parentId = localStorage.getItem("parentGroupId");
    // Loading previously used accesskey.
    if (typeof loadedAccessKey !== "undefined") {
      return loadedAccessKey.toString();
      // Using the currently received accessKey
    } else if (history.location?.state?.accessKey) {
      return history.location?.state?.accessKey;
      // Using the accessKey of the parent
    } else if (parentId) {
      let loadedAccessKeyForChildGroup = keys[parentId];
      // Loading previously used accesskey.
      if (typeof loadedAccessKeyForChildGroup !== "undefined") {
        return loadedAccessKeyForChildGroup.toString();
        // Using the currently received accessKey
      } else {
        return null;
      }
    } else if (urlAccessKey) {
      return urlAccessKey;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const qdakAccessKey = getQdakAccessKey();

    if (id && !qdakAccessKey && !paramAccessKey && !localStorage.getItem(id)) {
      history.push("/");
    } else if (id && qdakAccessKey) {
      if (id !== null && (shopId !== null || localStorage.getItem("shopId"))) {
        fetchHeader({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: qdakAccessKey,
          id: id,
        });
        fetchCover({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          id: id,
        });
        fetchGroups({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: qdakAccessKey,
          id: id,
        });
      }
    } else if (id && paramAccessKey) {
      if (id !== null && (shopId !== null || localStorage.getItem("shopId"))) {
        fetchHeader({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: paramAccessKey,
          id: id,
        });
        fetchCover({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          id: id,
        });
        fetchGroups({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: paramAccessKey,
          id: id,
        });
      }
    } else if (id && localStorage.getItem(id)) {
      if (id !== null && (shopId !== null || localStorage.getItem("shopId"))) {
        fetchHeader({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: localStorage.getItem(id),
          id: id,
        });
        fetchCover({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          id: id,
        });
        fetchGroups({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: localStorage.getItem(id),
          id: id,
        });
      }
    }
    if(shopId !== -1){
      localStorage.setItem("shopGalleryId", id);
      localStorage.setItem("shopId", shopId);
    }
    
  }, [id, shopId]);

  useEffect(() => {
    if (products?.length <= 0 || products?.length === undefined) {
      document.body.style.overflowY = "visible";
    }
    if (
      products?.length > imageCounter &&
      products.length > 0 &&
      typeof products?.length !== "undefined"
    ) {
      document.body.style.overflowY = "hidden";
    }
    if (imageCounter >= products?.length) {
      document.body.style.overflowY = "visible";
    }
  }, [imageCounter, products]);

  useEffect(() => {
    const qdakAccessKey = getQdakAccessKey();

    if (id && !qdakAccessKey && !paramAccessKey && !localStorage.getItem(id)) {
      history.push("/");
    } else if (id && qdakAccessKey) {
      if (
        !header?.belongTo &&
        id !== null &&
        (shopId !== null || localStorage.getItem("shopId"))
      ) {
        fetchProducts({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: qdakAccessKey,
          id: id,
          queryString: getQueryString({
            filterKey: null,
            filterValue: null,
          }),
        });
      }
    } else if (id && paramAccessKey) {
      if (
        !header?.belongTo &&
        id !== null &&
        (shopId !== null || localStorage.getItem("shopId"))
      ) {
        fetchProducts({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: paramAccessKey,
          id: id,
          queryString: getQueryString({
            filterKey: null,
            filterValue: null,
          }),
        });
      }
    } else if (id && localStorage.getItem(id)) {
      if (
        !header?.belongTo &&
        id !== null &&
        (shopId !== null || localStorage.getItem("shopId"))
      ) {
        fetchProducts({
          shopId:
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId"),
          accessKey: localStorage.getItem(id),
          id: id,
          queryString: getQueryString({
            filterKey: null,
            filterValue: null,
          }),
        });
      }
    }
  }, [currentPage, pageSize, id, shopId]);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Böngésző</title>
        <meta
          name="description"
          content="Böngéssz a képek között, hogy megtaláld a megfelelőt számodra."
        />
      </Helmet>
      <BreadcrumbWrapper>
        <Breadcrumb
          header={header}
          sticky={stickyBreadcrumb}
          shopId={
            location.search === "" && shopId
              ? shopId
              : localStorage.getItem("shopId")
          }
        />
      </BreadcrumbWrapper>

      {headerLoading ? (
        <LoadIndicator color={theme.palette.primary.textContent} height={900} />
      ) : (
        <HomeWrapper>
          <Slide direction="down" in={true} timeout={700}>
            <IntroSection>
              <BackgroundWrapper>
                <BackgroundImage src={cover || background} />
              </BackgroundWrapper>
              <TitleContainer>
                <Title>{header?.name}</Title>
              </TitleContainer>
            </IntroSection>
          </Slide>
          {header?.modifiers && getValidUntilFields(header?.modifiers)}
          {loading ? (
            <LoadIndicator color={theme.palette.primary.textContent} />
          ) : (
            <>
              {groups && (
                <GroupsWrapper id="groups">
                  {groups
                    ?.sort((a, b) => {
                      return a.name.localeCompare(b.name);
                    })
                    .map((group, idx) => {
                      return (
                        <GroupCard
                          groupId={group?.id}
                          groupCategory={group.category}
                          key={idx}
                          groupName={group.name}
                          imgSrc={group.url ? group.url : defaultImage}
                          belongsToId={header?.id}
                          qdakAccessKey={getQdakAccessKey}
                        />
                      );
                    })}
                </GroupsWrapper>
              )}

              {header?.belongsTo !== null && (
                <ProductsWrapper>
                  <TablePagination
                    hidden={
                      productsLength <= 10 || productsLength === undefined
                    }
                    component="div"
                    count={productsLength === undefined ? 0 : productsLength}
                    page={currentPage !== 0 && currentPage - 1}
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    labelRowsPerPage={"Termékek: "}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <Products
                    group={header}
                    qdakAccessKey={getQdakAccessKey()}
                    products={products}
                    id={id}
                    shopId={
                      location.search === "" && shopId
                        ? shopId
                        : localStorage.getItem("shopId")
                    }
                    path={background}
                    pageSize={pageSize}
                    setImageCounter={setImageCounter}
                    imageCounter={imageCounter}
                  />

                  <TablePagination
                    hidden={
                      productsLength <= 10 || productsLength === undefined
                    }
                    component="div"
                    count={productsLength === undefined ? 0 : productsLength}
                    page={currentPage !== 0 && currentPage - 1}
                    rowsPerPageOptions={[10, 15, 20, 25]}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    labelRowsPerPage={"Termékek: "}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </ProductsWrapper>
              )}
            </>
          )}
        </HomeWrapper>
      )}
      <ShopNotificationDialog
        open={props.showValidUntil}
        setOpen={props.setShowValidUntil}
      />
    </main>
  );
};

export default Group;
