import { useState } from "react";

export const useGetQueryString = () => {
  const [sortKey, setSortKey] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");

  const getQueryString = ({ filterKey, filterValue }) => {
    //add filterKey and filterValue in every condition if you won't use them, add them as nulls
    if (filterValue && filterKey) {
      return `?${filterKey}=${filterValue}&page=${currentPage}&size=${pageSize}&sort=${
        sortKey ? sortKey : "id"
      },${sortOrder}`;
    }
    return `&page=${currentPage}&size=${pageSize}&sort=${
      sortKey ? sortKey : "name"
    },${sortOrder}`;
  };
  return {
    sortKey,
    setSortKey,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    sortOrder,
    setSortOrder,
    getQueryString,
  };
};
