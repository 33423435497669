import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import greyer_blende from "../../assets/images/svg/blende_greyer.svg";

export const useStyles = makeStyles((theme) => ({
  validUntil: {
    textAlign: "center",
    color: "black",
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
}));

export const ProductsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const IntroSection = styled.div`
    width: 100%;
    margin: 0 0 2rem 0;
    display: inline-block;
    flex-direction: column;
    z-index: 50;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  background-color: whitesmoke;
  // background-image: url(${greyer_blende}), url(${greyer_blende}), url(${greyer_blende}), url(${greyer_blende});
  // background-repeat: no-repeat;
  // background-size: 30%, 40%, 40%, 30%;
  // background-position-X: -20%, 10%, 90%, 120%;
  // background-position-Y: 40px, -600px, -600px, 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40vh;
  position: relative;
`;



export const BackgroundImage = styled.img`
  width: auto;
  max-width: 100%;
  z-index: 1;
  height: 40vh;
`;

export const TitleContainer = styled.div`
  border: 2px solid transparent;
  border-radius: 0 0 40px 40px;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  text-align: center;
  background: ${(props) => props.theme.palette.primary.backgroundPrimary};
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundSecondary};
  margin: 1rem 0;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const GroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3rem;
`;
export const BreadcrumbWrapper = styled.div`
  height: 56px;
  z-index: 100;
`;

