/** @format */

import { Divider, useTheme } from "@material-ui/core";
import { BlendeImageWrapper, BlendeImageWrapper2, IntroButton, OtherButton, StyledLink, useStyles } from "./styles";
import Center from "components/General/center/Center";
import styles from "./InstitutionInfoPage.module.css";
import { Helmet } from "react-helmet";
import {
    MdAccessTime,
    MdLaptopMac,
    MdPeople,
    MdSecurity,
} from "react-icons/md";
import LabelledIconWidget from "components/LabelledIconWidget/LabelledIconWidget";
import institution_image from "assets/images/institution.png";
import frustration from "assets/images/frustration.jpg";
import blende_grey from "assets/images/svg/blende_grey.svg";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const InstitutionInfoPage = (props) => {
    const theme = useTheme();
    const target = useRef(null);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <Helmet>
                <title>Iskolaévkönyv - Intézményeket keresünk</title>
                <meta
                    name="description"
                    content="Keressük azon intézményeket ahol számít a minőségi iskolai fotózás, bölcsődei fotózás, vagy bármilyen intézményi fotózás. Egyszerűsítsd le a menedzsmentbeli terheket, és használj egy modern platformot."
                />
            </Helmet>
            <section className={styles.header_section}>
                <BlendeImageWrapper
                    style={{
                        background: `url(${blende_grey})`,
                        backgroundSize: "172%",
                        backgroundPositionX: "0rem",
                        backgroundPositionY: "25rem",
                    }}
                />
                <BlendeImageWrapper2
                    style={{
                        background: `url(${blende_grey})`,
                        backgroundSize: "200%",
                        backgroundPositionX: "-12rem",
                        backgroundPositionY: "-8rem",
                    }}
                />
            <Center element="section" sx={styles.custom_center} color={theme.palette.primary.backgroundSecondary}>
                <div className={styles.hero}>
                    <div>
                        <h1 className={styles.header_title}>
                            Intézményeket keresünk !
                        </h1>
                        <div style={{color: 'whitesmoke'}}>
                            <b>
                                Digitalizálja az intézményi fotózást
                                Magyarország legteljeskörűbb fotózási
                                platformjával!
                            </b>{" "}
                            <br /> <br /> Ön is fontosnak tartja, hogy az
                            intézményében tanuló diákokról gyorsan, rövid idő
                            alatt, jó minőségű fotók készüljenek? Nem szeretné
                            ezzel terhelni a kollégákat?
                            <br /> <br />
                            Egyszerű és gyors folyamatot keres a fotózás
                            lebonyolításához? <br /> Felejtse el a papír alapú
                            megrendeléseket és a pénzbeszedést.
                        </div>
                        <div className={styles.buttons}>
                            <IntroButton
                                onClick={() =>
                                    target.current.scrollIntoView({
                                        behavior: "smooth",
                                    })
                                }
                                className={styles.button}
                            >
                                Érdekel
                            </IntroButton>
                            <OtherButton
                                onClick={() =>
                                    history.push("/fotosokat-keresunk")
                                }
                                className={styles.button}
                            >
                                Fotósoknak
                            </OtherButton>
                        </div>
                    </div>
                    <img
                        alt="Intézményeket keresünk az iskolaévkönyvhöz."
                        src={institution_image}
                        width={300}
                        height={300}
                        className={styles.institution_image}
                    ></img>
                </div>
            </Center>
            </section>
            <Center
                element="section"
                sx={styles.custom_center}
                color="rgb(241, 245, 249)"
            >
                <div className={styles.container}>
                    <div>
                        <img
                            alt="Túl sok az adminisztráció?"
                            src={frustration}
                            className={styles.frustrated_image}
                        ></img>
                    </div>
                    <div
                        className={
                            styles.section_padding + " " + styles.problems
                        }
                    >
                        <h2>Van megoldás!</h2>

                        <p>
                            A Datatronic által fejlesztett <b>ISKOLAÉVKÖNYV</b>{" "}
                            rendszer képes kezelni a teljes folyamatot, a{" "}
                            <b>fotók megrendelésétől</b> kezdve a{" "}
                            <b>bankkártyás fizetésen át a számlázásig</b>.
                            Mindezt egy{" "}
                            <b>
                                egyszerű, felhasználóbarát és biztonságos webes
                                felület
                            </b>{" "}
                            segítségével.
                        </p>
                        <p>
                            Iskolaévkönyv portál kifejezetten az intézményi
                            fotózás specialitásaihoz igazodik, és működése
                            megfelel a GDPR és a hatályos jogszabályok
                            előírásainak is. Többféle bankkártyás fizetést és
                            automatikus számlázási módot is tartalmaz. A szülők
                            mindenről email értesítést kapnak, a teljes
                            rendelési folyamatot nyomon követhetik és szükség
                            esetén segítséget kérhetnek a fotóstól vagy az
                            Iskolaévkönyv ügyfélszolgálatától.{" "}
                            <StyledLink
                                href="/customer-faq"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/customer-faq");
                                }}
                            >
                                Gyakori Kérdések Vásárlóknak
                            </StyledLink>
                        </p>
                    </div>
                </div>
            </Center>
            <Center element="section" sx={styles.custom_center}>
                <div className={styles.section_padding}>
                    <h2 style={{ textAlign: "center" }}>Előnyök</h2>
                    <Divider />
                    <div className={styles.widget_container}>
                        <div>
                            <LabelledIconWidget
                                icon={
                                    <MdAccessTime
                                        title="Időmegtakarítás"
                                        fill="white"
                                    />
                                }
                                title={"Időmegtakarítás"}
                            >
                                Az Iskolaévkönyv használata rengeteg időt
                                szabadít fel. A hozzáférési kódok kiküldésén és
                                a kész fotók kiosztásán kívül a tanároknak
                                semmilyen adminisztrációs feladata nincs.
                            </LabelledIconWidget>
                        </div>
                        <div>
                            <LabelledIconWidget
                                icon={
                                    <MdSecurity
                                        title="Biztonság"
                                        fill="white"
                                    />
                                }
                                title={"Biztonság"}
                            >
                                Biztonságos képkezelés, mely során mindenki
                                nyugodt lehet, hogy a gyermekek fotói nem
                                kerülhetnek illetéktelen kezekbe. GDPR-nak és az
                                oktatási törvénynek is megfelelő működés.
                            </LabelledIconWidget>
                        </div>
                        <div>
                            <LabelledIconWidget
                                icon={
                                    <MdPeople
                                        title="Elégedettség"
                                        fill="white"
                                    />
                                }
                                title={"Elégedettség"}
                            >
                                Nem csak a szülők és a tanárok lesznek
                                elégedettebbek. De a fotós számára is több
                                vásárlás lesz elérhető.
                            </LabelledIconWidget>
                        </div>
                        <div>
                            <LabelledIconWidget
                                icon={
                                    <MdLaptopMac
                                        title="Egyszerű webes felület"
                                        fill="white"
                                    />
                                }
                                title={"Támogatás"}
                            >
                                Professzionális fejlesztői háttér, folyamatos
                                ügyfél támogatás. Egyre bővülő funkciók. <br />
                                Az Iskolaévkönyvre hosszú távon számíthat!
                            </LabelledIconWidget>
                        </div>
                    </div>
                    <Divider />
                </div>
            </Center>
            <Center
                element="section"
                sx={styles.custom_center}
                color="rgb(241, 245, 249)"
            >
                <div className={styles.section_padding} ref={target}>
                    <h2>Van egy jó hírünk!</h2>
                    <div>
                        Ahhoz, hogy az Önök intézményében is Iskolaévkönyvön
                        keresztül történhessenek a fotózások,{" "}
                        <b>nem kell lecserélnie a már meglévő fotósát!</b>
                        <br />
                        <br />
                        Egyszerűen elég, ha megkéri, hogy ismerje meg az
                        Iskolaévkönyvet valamelyik következő workshopunkon vagy
                        egy külön bemutató keretében, és csatlakozzon
                        felhasználóink közé.
                        <br />
                        <br />
                        Ehhez kérjük töltse ki a lenti kérdőívet, hogy
                        felvehessük a kapcsolatot az intézményfotósával. Így Ön
                        akár már a következő iskolai fotózásnál tapasztalhatja a
                        pozitív változást.
                        <br />
                        <br />
                        Amennyiben nincs fotósa, mi tudunk ajánlani fotós kollégát. 
                    </div>
                    <br />
                    <br />
                    {/* <iframe
                        title="Google Form"
                        src="https://forms.office.com/r/yi6xxpS4pt?origin=lprLink"
                        width="100%"
                        height="900"
                        frameborder="0"
                        marginheight="0"
                        marginwidth="0"
                    >
                        Loading…
                    </iframe> */}
                    <div style={{ width: "100%" }}>
                        <p>
                            A gombra kattintva külön oldalon fog megnyílni a
                            kérdőív. Vedd fel velünk a kapcsolatot, hogy minél
                            előbb megkezdhessük a közös munkát!
                        </p>
                        <IntroButton
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "1rem auto",
                            }}
                            onClick={() =>
                                window.open(
                                    "https://forms.office.com/r/yi6xxpS4pt?origin=lprLink",
                                    "_blank"
                                )
                            }
                        >
                            Kitöltöm a kérdőívet
                        </IntroButton>
                    </div>
                </div>
            </Center>
        </main>
    );
};

export default InstitutionInfoPage;
