import { Grid, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@mui/material";
import React from "react";
import { useEffect } from "react";

import { usePostalCodeFields } from "./hooks/usePostalCodeFields";
import { useStyles } from "./styles";

const PostalCodeFields = ({ data, setData, formik }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { calcAddress, calcPostalCode, calcAddressSuggestions } =
    usePostalCodeFields();

  useEffect(() => {
    setData({ ...data, zip: data?.zip });
    if (data?.zip?.length > 0) {
      let suggestion = calcAddress(data?.zip);
      if (suggestion === data?.zip) return;
      if (typeof suggestion === "undefined") {
        setData({ ...data, city: "" });
        return;
      } else {
        setData({ ...data, city: suggestion });
      }
    }
  }, [data?.zip]);

  useEffect(() => {
    setData({ ...data, city: data?.city });
    if (data?.city?.length > 0) {
      let suggestion = calcPostalCode(data?.city);
      if (suggestion === data?.city) return;
      if (typeof suggestion === "undefined") {
        setData({ ...data, zip: "" });
        return;
      } else {
        setData({ ...data, zip: suggestion });
      }
    }
  }, [data?.city]);

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} md={6} >
        <TextField
          label={"Irányítószám"}
          id="zip"
          name="zip"
          className={classes.zip}
          required
          placeholder="0000"
          value={data?.zip}
          key="postal_code_field"
          onChange={(e) => {
            formik?.handleChange(e);
            setData({ ...data, zip: e.target.value });
          }}
          error={formik?.touched?.zip && Boolean(formik?.errors?.zip)}
          helperText={formik?.touched?.zip && formik?.errors?.zip}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={calcAddressSuggestions(data?.city)}
          inputValue={data?.city}
          className={classes.autoComplete}
          sx={{ fontFamily: `${theme.typography.main}, Sans-serif, Arial` }}
          onChange={(e) =>
            setData({
              ...data,
              city: e.target.innerHTML.includes("<") ? "" : e.target.innerHTML,
            })
          }
          renderInput={(params) => (
            <TextField
              id="city"
              required
              name="city"
              value={data?.city}
              className={classes.city}
              {...params}
              label={"Város"}
              key="address_field"
              onChange={(e) => {
                formik?.handleChange(e);
                setData({ ...data, city: e.target.value });
              }}
              error={formik?.touched?.city && Boolean(formik?.errors?.city)}
              helperText={formik?.touched?.city && formik?.errors?.city}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default PostalCodeFields;
