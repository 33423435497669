import styled from "styled-components";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { forwardRef } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import SIZES from "constants/sizes";
import Slide from "@material-ui/core/Slide";

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;

  @media (min-width: ${SIZES.MD}px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 1000px;
  }
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.textSecondary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const ProductName = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.textSecondary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "1000px",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: "20",
      background: theme.palette.primary.backgroundSecondary,
      [theme.breakpoints.up("md")]: {
        padding: "2rem",
      },
    },
  },
  dialogContent: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
    },
  },
  dialogContext: {
    marginTop: "1rem",
    color: theme.palette.primary.textSecondary,
    fontWeight: "bold",
    textTransform: "uppercase !important",
    fontFamily: theme.typography.secondary,
    width: "100%",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },

  fileName: {
    marginTop: "1rem",
    color: theme.palette.primary.textSecondary,
    fontFamily: theme.typography.secondary,
    textAlign: "center",
    width: "100%",
  },

  stepper: {
    width: "100%",
    minWidth: "300px",
    padding: 0,
    margin: 0,
    color: theme.palette.primary.textSecondary,
    background: theme.palette.primary.backgroundSecondary,
  },
  basketIcon: {
    position: "absolute",
    zIndex: "70",
    top: "0.5rem",
    left: "0.5rem",
    fontSize: "1.5rem",
    color: theme.palette.primary.textSecondary,
  },
  stepLabel: {
    color: theme.palette.primary.textSecondary,
  },
  button: {
    color: theme.palette.primary.textSecondary,
  },
  toCartBtn: {
    background: theme.palette.primary.backgroundPrimary,
    color: theme.palette.primary.textSecondary,
    '&:hover':{
      color: 'black',
      background: theme.palette.primary.primaryGrey ,
    }
  },
  payButton: {
    color: theme.palette.primary.textPrimary,
    background: theme.palette.primary.backgroundPrimary,
    '&:hover':{
      color: 'black',
      background: theme.palette.primary.primaryGrey ,
    }
  },
  totalPrice: {
    marginBottom: "0.5rem",
    color: theme.palette.primary.textSecondary,
  },
  spinner: {
    color: `${theme.palette.primary.textPrimary} !important`,
  },
}));

export const ButtonsWrapper = styled.div`
  align-self: center;
  height: 1rem;
`;

export const ProductCard = styled.div`
  border-radius: 4px;
  zindex: 1;
  text-align: center;
  height: 200px;
  flex: 0 0 calc(100% - 20px);
  margin: 10px;
  position: relative;
  box-shadow: ${(props) =>
    props.hover
      ? "0 4px 15px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.4)"
      : "0 4px 15px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.2)"};

  &:hover {
    cursor: pointer;
  }
  @media (min-width: ${SIZES.XS}px) {
    flex: 0 0 calc(50% - 20px);
  }

  @media (min-width: ${SIZES.SM}px) {
    flex: 0 0 calc(33% - 20px);
  }

  @media (min-width: ${SIZES.MD}px) {
    flex: 0 0 calc(25% - 20px);
    height: 300px;
  }
  background: ${(props) => props.theme.backgroundContent};
`; // TODO fix this it doesnt work

export const Image = styled.img`
  margin: auto;
  height: 100%;
`;

export const MediaSection = styled.div``;

export const DialogImage = styled.img`
  margin: auto;
  height: 100%;
  max-width: 100%;
`;

export const OverlayHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  zindex: 100;
`;
export const CartIconWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary.backgroundSecondary};
  position: absolute;
  border-radius: 0 0 0 0 4px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 20;
  width: 65px;
  height: 65px;
`;

export const HeartHolder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  zindex: 1;
  height: 40px;
  border-radius: 4px 0 0 0;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary};
  boxshadow: inset 0 6px 15px 0 rgba(0, 0, 0, 0.3);
`;

const StyledFilledHearth = withTheme(styled(IoMdHeart)`
  fill: white;
  height: 40px;
  width: 40px;
`);

const StyledHearth = withTheme(styled(IoMdHeartEmpty)`
  fill: ${(props) => props.theme.palette.primary.textSecondary};
  height: 40px;
  width: 40px;
`);

export const HeartIcon = ({ isLiked, toggleLike }) => {
  return (
    <HeartHolder onClick={(e) => toggleLike(e)}>
      {isLiked ? <StyledFilledHearth /> : <StyledHearth />}
    </HeartHolder>
  );
};
