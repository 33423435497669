import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btnGroup: {
    position: "absolute",
    bottom: "20px",
    left: "0",
    flexDirection: "row",
    alignItems: "center",
  },

  input: {
    "& .MuiFormLabel-root, & .MuiInputBase-root": {
      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    },
  },
}));
