import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import {
  PDetailsCard,
  PDetailsTitle,
  PDetailsWrapper,
  useStyles,
  IconWrapper,
  PDetailsBody,
  IdText,
  DetailsMessage,
  LoadingMessage,
  HomeBtn,
  DetailsTitle
} from './styles';
import { useParams } from 'react-router-dom';
import { usePaymentAPI } from './hooks/usePaymentAPI';
import Grow from '@material-ui/core/Grow';
import { Helmet } from 'react-helmet';

export const PaymentDetails = () => {
  const { search } = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { checkoutId } = useParams();

  const { getExternalCheckoutId, externalId, externalOk, externalIdLoading, paymentDetails } = usePaymentAPI();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const s = encodeURIComponent(params.get('s'));
    const r = params.get('r');
    getExternalCheckoutId(checkoutId, { r: r, s: s }, localStorage.getItem('shopId'));
  }, [checkoutId]);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Fizetés</title>
        <meta name='description' content='A fizetéssel/megrendeléssel kapcsolatos információkat itt találod.' />
      </Helmet>
      <PDetailsWrapper>
        {externalIdLoading ? (
          <PDetailsCard>
            <LoadingMessage>
              <CircularProgress style={{ width: '70px', height: '70px' }} />
              <h1>Fizetés folyamatban, kérjük ne navigáljon el az oldalról</h1>
            </LoadingMessage>
          </PDetailsCard>
        ) : (
          <Grow in={true} timeout={700} unmountOnExit>
            <PDetailsCard>
              {/* <CardBack />
              <CardBack2 /> */}
              {externalOk && (
                <>
                  <PDetailsTitle className={classes.title}>
                    <IconWrapper>
                      <svg className={classes.successCheck} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
                        <circle className={classes.successCheckCircle} cx='26' cy='26' r='25' fill='none' />
                        <path className={classes.succcesCheckCheck} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                      </svg>
                    </IconWrapper>
                    <h1 className={classes.cardTitle}>{`Fizetése sikeres volt.`}</h1>
                  </PDetailsTitle>
                  <PDetailsBody>
                    <DetailsTitle>Köszönjük megrendelését!</DetailsTitle>
                    <DetailsMessage>
                      Rendelésed részleteiről email üzenetet küldtünk, az általad megadott email címre.
                    </DetailsMessage>
                    <IdText>{`Sikeres tranzakció.`}</IdText>
                    <IdText>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</IdText>
                    <IdText>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </IdText>
                    <HomeBtn href='/'>Vissza a főoldalra</HomeBtn>
                  </PDetailsBody>
                </>
              )}
              {paymentDetails?.status === 'REJECTED' && (
                <>
                  <PDetailsTitle className={classes.title}>
                    <IconWrapper className={classes.fail}>
                      <CloseIcon className={classes.failIcon} />
                    </IconWrapper>
                    <h1 className={classes.cardTitle}>{`Fizetés sikertelen.`}</h1>
                  </PDetailsTitle>
                  <PDetailsBody className={classes.failedBody}>
                    <DetailsMessage>
                      Kérjük, ellenőrizd a tranzakció során megadott adatok helyességét. Amennyiben minden adatot
                      helyesen adtál meg, a visszautasítás okának kivizsgálása kapcsán kérjük, szíveskedj kapcsolatba
                      lépni kártyakibocsátó bankoddal.
                    </DetailsMessage>
                    <IdText>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</IdText>
                    <IdText>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </IdText>
                    <HomeBtn href='/'>Vissza a főoldalra</HomeBtn>
                  </PDetailsBody>
                </>
              )}
              {paymentDetails?.status === 'CANCELLED' && (
                <>
                  <PDetailsTitle className={classes.title}>
                    <IconWrapper className={classes.fail}>
                      <CloseIcon className={classes.failIcon} />
                    </IconWrapper>
                    <h1 className={classes.cardTitle}>{`Fizetés megszakítva.`}</h1>
                  </PDetailsTitle>
                  <PDetailsBody className={classes.failedBody}>
                    <IdText>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</IdText>
                    <IdText>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </IdText>
                    <HomeBtn href='/'>Vissza a főoldalra</HomeBtn>
                  </PDetailsBody>
                </>
              )}
              {paymentDetails?.status === 'TIMEOUT' && (
                <>
                  <PDetailsTitle className={classes.title}>
                    <IconWrapper className={classes.fail}>
                      <CloseIcon className={classes.failIcon} />
                    </IconWrapper>
                    <h1 className={classes.cardTitle}>{`Időtúllépés.`}</h1>
                  </PDetailsTitle>
                  <PDetailsBody className={classes.failedBody}>
                    <DetailsMessage>Időtúllépés miatt fizetésed sikertelen.</DetailsMessage>
                    <IdText>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</IdText>
                    <IdText>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </IdText>
                    <HomeBtn href='/'>Vissza a főoldalra</HomeBtn>
                  </PDetailsBody>
                </>
              )}
            </PDetailsCard>
          </Grow>
        )}
      </PDetailsWrapper>
    </main>
  );
};
