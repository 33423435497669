/** @format */

import {
  Description,
  HomeWrapper,
  PairContainer,
  WidgetContainer,
  useStyles,
  StyledLink,
  ContactButton,
  IconLink
} from './styles';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import IntroSection from './Sections/IntroSection/IntroSection';
import { useRef } from 'react';
import { useEffect } from 'react';
import { setShop } from 'redux/slices/ShopSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Center from 'components/General/center/Center';
import page2Head from 'assets/images/png/page2_head.jpg';
import page4Head from 'assets/images/png/page4_head.jpg';
import page3Head from 'assets/images/png/page3_head.jpg';
import page5Head from 'assets/images/png/page5_head.jpg';
import LabelledIconWidget from 'components/LabelledIconWidget/LabelledIconWidget';
import { MdAccessTime, MdBuild, MdSecurity, MdPeople, MdLaptopMac } from 'react-icons/md';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CameraAlt, SchoolRounded } from '@material-ui/icons';

const Landing = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const desktop = useMediaQuery('(min-width:900px)');

  useEffect(() => {
    document.body.style.overflow = 'visible';
    localStorage.setItem('shopId', -1);
    dispatch(setShop(localStorage.getItem(shopId)));
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - A digitalizált iskolai fotózás otthona</title>
        <meta
          name='description'
          content='Egyedülálló fotóportál. Digitalizált iskolai fotózás, bölcsődei fotózás, óvodai fotózás és intézményi fotózás egy modern platformon. Professzionális fotósaink garantálják a lenyűgöző fotókat, legyen szó gyermekfotókról, portrékról vagy csoportképekről.'
        />
      </Helmet>
      <HomeWrapper ref={containerRef}>
        <IntroSection />
        {/*Kik vagyunk*/}
        <Center element='section'>
          <div
            style={{
              margin: 0,
              marginBottom: '5rem',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <h2 style={{ textAlign: 'center', fontSize: '30px' }}>Kik vagyunk mi</h2>
            <Divider />
            <p className={classes.introduction}>
              Az Iskolaévkönyv, a Datatronic Kft által fejlesztett innovatív platform, több éves szakmai munka
              eredményeként jött létre. Célja, hogy megkönnyítse az intézményi fotózás teljes adminisztrációs
              folyamatát. A rendszer biztonságosan kezeli a fotók publikálását, támogatja a webes rendelést, a
              bankkártyás fizetést, az automatikus számlázást és a digitális képek kiküldését.
            </p>
            <div className={classes.links}>
              <div className={classes.linkWrapper1}>
                <IconLink onClick={() => history.push('/fotosokat-keresunk')}>
                  <CameraAlt />
                </IconLink>
                <h3>Fotósoknak</h3>
              </div>
              <div className={classes.linkWrapper2}>
                <IconLink onClick={() => history.push('/oktatasi-intezmenyeket-keresunk')}>
                  <SchoolRounded />
                </IconLink>
                <h3>Intézményeknek</h3>
              </div>
            </div>
            {/*Iskolaévkönyv előnyei*/}
            <h2 style={{ textAlign: 'center', fontSize: '30px' }}>Iskolaévkönyv előnyei</h2>
            <Divider />
            <WidgetContainer>
              <div className={classes.widget}>
                <LabelledIconWidget
                  icon={<MdAccessTime title='Időmegtakarítás' fill='white' />}
                  title={'50% Időmegtakarítás'}>
                  Az Iskolaévkönyv használata rengeteg időt szabadít fel tanárnak, szülőnek, fotósnak egyaránt az
                  automatizmusnak és a szabályozott folyamatoknak köszönhetően.
                </LabelledIconWidget>
              </div>
              <div className={classes.widget}>
                <LabelledIconWidget icon={<MdSecurity title='Adatbiztonság' fill='white' />} title={'Adatbiztonság'}>
                  A Datatronicnál első az adatbiztonság. Az Iskolaévkönyv épp ezért megfelel a GDPR-nak, az oktatási
                  törvénynek és az ISO27001 adatbiztonsági előírásoknak is. <br /> A gyermekek fotói nem kerülhetnek
                  illetéktelen kezekbe!
                </LabelledIconWidget>
              </div>
              <div className={classes.widget}>
                <LabelledIconWidget icon={<MdPeople title='Elégedettség' fill='white' />} title={'Elégedettség'}>
                  Működésünk során már számtalan szülői, diák, iskolai és nem utolsó sorban fotós visszajelzést kaptunk
                  a rendszer hatékonyságára vonatkozóan.
                </LabelledIconWidget>
              </div>
              <div className={classes.widget}>
                <LabelledIconWidget
                  icon={<MdLaptopMac title='Egyszerű webes felület' fill='white' />}
                  title={'Egyszerű webes felület'}>
                  Felhasználóbarát, könnyen kezelhető felület. Más webshopoknál megszokott rendelési és fizetési
                  folyamat. Elégedett szülők és átlagosan 30% többletbevétel a papíralapú rendeléshez képest.
                </LabelledIconWidget>
              </div>
              <div className={classes.widget}>
                <LabelledIconWidget icon={<MdBuild title='Profi IT háttér' fill='white' />} title={'Profi IT háttér'}>
                  A Datatronic biztosítja a professzionális fejlesztői hátteret, a legmodernebb felhős infrastruktúrát,
                  biztonságos és gyors üzemeltetést, ügyfélszolgálatot, valamint szakmai és marketing támogatást.
                </LabelledIconWidget>
              </div>
            </WidgetContainer>
            <Divider />
          </div>
        </Center>
        <br />
        <br />
        {/*Intézményeknek*/}
        <Center element='section' color='#f1f5f9'>
          <PairContainer>
            <img
              src={page2Head}
              alt='Intézményi fotózás, iskolai fotózás'
              title='Intézményi fotózás, iskolai fotózás'
              width='100%'
              style={{ alignSelf: 'center' }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              <h2>Fotózással kapcsolatos teendők</h2>
              <p>
                <b>Fotózás megszervezése</b> - Egyeztessen egy időpontot a fotóssal, értesítse a szülőket és készítse
                elő a helyszínt. Legyen szó tabló fotózásról, osztályfényképezésről vagy éppen bármilyen intézményi
                fotózásról.
                <br />
                <br />
                <b>Hozzáférési kód továbbítása</b> - A fotózást követően rövid időn belül a szülők egy kód beírásával
                férnek majd hozzá a képekhez. Nálunk első a biztonság!
                <br />
                <br />
                <b>A kész képek kiosztása:</b> - A fotók rendelésenként külön csomagolva érkeznek. A digitális képek
                kiküldése automatikusan történik.
              </p>
              <b>Nincs több:</b>
              <ul className={classes.list}>
                <li>Készpénz kezelés</li>
                <li>Rendelési lapokkal való bajlódás </li>
                <li>Fotó szortírozás</li>
                <li>Szülői reklamáció kezelés </li>
                <li>Közvetítő szerepből adódó plusz kommunikáció</li>
                <li>Aggodalom a jogszerűség miatt</li>
              </ul>
              <ContactButton
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/oktatasi-intezmenyeket-keresunk');
                }}
                target='_blank'
                href='/oktatasi-intezmenyeket-keresunk'>
                Még több infó
              </ContactButton>
            </div>
          </PairContainer>
        </Center>

        {/*Fotósknak*/}
        <Center element='section'>
          <PairContainer>
            <Description>
              <h2 style={{ width: '100%' }}>Fotós vagy?</h2>
              <p style={{ width: '100%' }}>Elmondjuk miért éri meg minket választani! </p>
              <ul className={classes.list}>
                <li>
                  <b>Kevesebb adminisztráció</b> - A sok fölösleges adminisztráció helyett koncentráljon a fotózásra, a
                  többit intézzük mi!
                </li>
                <li>
                  <b>Időmegtakarítás</b> - A rendszerbe beépített automatizmusok miatt akár 50%-ot spórolhat az
                  idejéből.
                </li>
                <li>
                  <b>Több bevétel</b> - A digitális rendelési felület segíti az eladást, ami akár 30% többletbevételt is
                  eredményezhet.
                </li>
              </ul>

              <br />
              <ContactButton
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/fotosokat-keresunk');
                }}
                target='_blank'
                href='/fotosokat-keresunk'>
                Még több infó
              </ContactButton>
              <br />
              <ContactButton target='_blank' href={window._env_.REACT_APP_QDAK_REGISTRATION_URL}>
                Fotós regisztráció
              </ContactButton>
            </Description>
            <img
              src={page4Head}
              alt='Iskolavékönyv a fotósoknak'
              title='Iskolaévkönyv a fotósoknak'
              width='100%'
              style={{
                alignSelf: 'center',
                gridRow: desktop ? 'unset' : '1'
              }}
            />
          </PairContainer>
        </Center>
        {/*Szülőknek*/}
        <Center element='section' color='#f1f5f9'>
          <PairContainer>
            <img
              src={page3Head}
              alt='Iskolaévkönyv a szülőknek'
              title='Iskolaévkönyv a szülőknek'
              width='100%'
              style={{ alignSelf: 'center' }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              <h2 style={{ color: 'transparent', margin: '0' }}>A szülő a digitális felületen intézheti:</h2>
              <p>
                <b>A képek kiválasztását</b> - A gyermekéről készült képek megtekintése, egyszerű webes felületen
                keresztül.
                <br />
                <br />
                <b>A rendelés leadását</b> - A digitális és nyomtatott képek, valamint fényképes ajándéktárgyak
                megrendelése, más webshopoknál megszokott módon.
                <br />
                <br />
                <b>A fizetést</b> - Oldalunkon biztonságos bankkártyás fizetéssel rendelheti meg fotóit.
                <br />
                <br />
                <b>A kommunikációt a fotóssal</b> - Egyszerűsített kapcsolatfelvétel, közvetlenül a fényképésszel.
              </p>
              <p>
                Érdemes lehet körülnézni a{'  '}
                <StyledLink
                  href='/customer-faq'
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/customer-faq');
                  }}>
                  Gyakori Kérdések Vásárlóknak
                </StyledLink>{' '}
                oldalunkon is, ha kérdései merültek fel.
              </p>
            </div>
          </PairContainer>
        </Center>
        <Center element='section'>
          <PairContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
              <h2 style={{ color: 'transparent', margin: '0' }}>Plusz előnyök:</h2>
              <p>
                <b>Hamarabb megtekinthető képek</b> - A fotózást követően rövid időn belül elérhetők a fotók.
                <br />
                <br />
                <b>Könnyített rendelési folyamat</b> - Távoli/külön élő családtagok is rendelhetnek, akár többször is.
                <br />
                <br />
                <b>Képek biztonságos kézbesítése</b> - A digitális képek kiküldése automatikus. A papírképeket már
                szortírozva kapja meg az iskola.
                <br />
                <br />
                <b>Nincs több bizonytalanság</b> - A szülők mindenről email értesítést kapnak.
                <br />
                <br />
                <b>Rendelési határidő módosítása</b> - A rendelési határidő digitális képekre vonatkozóan könnyen
                meghosszabítható.
              </p>
            </div>
            <img
              src={page5Head}
              alt='Iskolaévkönyv plusz előnyök'
              title='Iskolaévkönyv plusz előnyök'
              width='100%'
              style={{
                alignSelf: 'center',
                gridRow: desktop ? 'unset' : '1'
              }}
            />
          </PairContainer>
        </Center>
      </HomeWrapper>
    </main>
  );
};

export default Landing;
