import { Fragment } from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { AddressSchema } from 'validation/Checkout/Schemas';
import { useStyles } from './styles';
import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core';
import { useEffect } from 'react';

const AddressForm = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    props.setDeliveryTarget('Kérjük válasszon...');
    props.setPaymentMethod('OTP_SIMPLE');
  }, []);

  const formik = useFormik({
    initialValues: {
      address: props.data.address,
      city: props.data.city,
      zip: props.data.zip,
      country: props.data.country ? props.data.country : 'Magyarország',
      saveAddress: props.data.saveAddress,
      submitted: props.data.submitted
    },
    validationSchema: AddressSchema,
    onSubmit: () => {
      props.setData(formik.values);
      props.handleNext();
    }
  });

  return (
    <Fragment>
      <Typography className={classes.dialogTitle} variant='h6' gutterBottom>
        Szállítási információk
      </Typography>
      <Grid container spacing={3}>
        <Grid item container xs={12} align='center'>
          <FormControl required style={{ margin: '3rem 0 0 0', width: '100%', rowGap: '0.5rem' }}>
            <FormLabel
              style={{
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                marginTop: '1rem'
              }}>
              Válassza ki melyik osztályban szeretné átvenni a képeket!
            </FormLabel>
            <Select
              aria-labelledby='deliveryTarget'
              name='deliveryTarget'
              value={props.deliveryTarget}
              style={{
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
              }}
              defaultValue={'Kérjük válasszon...'}
              onChange={(e) => {
                props.setDeliveryTarget(e.target.value);
              }}>
              <MenuItem
                key={`kerjuk_valasszon`}
                value={'Kérjük válasszon...'}
                style={{
                  fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                }}>
                Kérjük válasszon...
              </MenuItem>
              {props?.deliveryTargets?.map((target) => {
                return (
                  <MenuItem
                    key={`${target}option`}
                    value={target}
                    style={{
                      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
                    }}>
                    {target}
                  </MenuItem>
                );
              })}
            </Select>
            {/* <RadioGroup
              aria-labelledby="deliveryMethod"
              name="deliveryMethod"
              value={props.deliveryMethod}
              defaultValue={"1"}
              onChange={(e) => {
                props.setDeliveryMethod(e.target.value);
              }}
              error={
                formik.touched.deliveryMethod &&
                Boolean(formik.errors.deliveryMethod)
              }
              helperText={
                formik.touched.deliveryMethod && formik.errors.deliveryMethod
              }
            >
              {props.deliveryMethods.map((method) => {
                return (
                  <div>
                    <FormControlLabel
                      disabled={method.key !== "1"}
                      key={method.key}
                      value={method.key}
                      control={<Radio color="primary" />}
                      label={method.name}
                    />
                  </div>
                );
              })}
            </RadioGroup>  THIS SECTION IS COMMENTED FOR QDAK'S SCHOOL VERSION*/}
          </FormControl>
          {/* <FormControl
            required
            style={{ margin: "1.5rem 0 1rem 0", width: "100%", rowGap: "0.5rem" }}
          >
            <FormLabel
              style={{
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`, marginTop: "1rem"
              }}
            >
              Válassza ki a fizetés módját!
            </FormLabel>
            <Select
              aria-labelledby="paymentMethod"
              name="paymentMethod"
              value={props.paymentMethod}
              style={{
                fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
              }}
              defaultValue={"Kérjük válasszon..."}
              onChange={(e) => {
                props.setPaymentMethod(e.target.value);
              }}
            >
              <MenuItem
                key={`kerjuk_valasszon`}
                value={"Kérjük válasszon..."}
                style={{
                  fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                }}
              >
                Kérjük válasszon...
              </MenuItem>
              {props?.paymentMethods?.map((target) => {
                return (
                  <MenuItem
                    key={target.key}
                    value={target.key}
                    style={{
                      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                    }}
                  >
                    {target.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {props?.paymentMethod === 'TRANSFER' && <p style={{color: 'red'}}>
            Átutalásos fizetési mód esetén kérjük a fizetés 72 órán belül
            történő rendezését, a rendelés leadásától számítva. k
            Tájékoztatjuk, hogy az utalások feldolgozása
            2 munkanapot is igénybe vehet!
            </p>} */}
        </Grid>
        <>
          {props.deliveryMethod === '1' && (
            <Grid container spacing={3}>
              <Grid item container justifyContent='center' direction='row' className={classes.btnGroup} spacing={3}>
                <Grid item container xs={6} md={3} justifyContent='center'>
                  <Button variant='outlined' onClick={props.handleBack}>
                    Vissza
                  </Button>
                </Grid>

                <Grid item container xs={6} md={3} justifyContent='center'>
                  <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    onClick={props.handleNext}
                    type='submit'
                    disabled={
                      props.deliveryTarget ===
                      'Kérjük válasszon...' /*|| props.paymentMethod === "Kérjük válasszon..."*/
                    }>
                    Tovább
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </Fragment>
  );
};

export default AddressForm;
