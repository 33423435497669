/** @format */

import { Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import {
    DetailsMessage,
    DetailsTitle,
    HomeBtn,
    IconWrapper,
    IdText,
    PDetailsBody,
    PDetailsTitle,
    useStyles,
} from "./styles";
import { IconButton } from "@mui/material";
import { MdOutlineContentCopy } from "react-icons/md";

const TransferDetails = ({ order }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    useEffect(() => {
        console.log(order);
    }, [order]);

    return (
        <Fragment>
            {/* <Typography
                className={classes.dialogTitle}
                variant="h6"
                gutterBottom
            >
                R
            </Typography> */}
            <Grid container spacing={3}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <PDetailsTitle className={classes.title}>
                        <IconWrapper>
                            <svg
                                className={classes.successCheck}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 52 52"
                            >
                                <circle
                                    className={classes.successCheckCircle}
                                    cx="26"
                                    cy="26"
                                    r="25"
                                    fill="none"
                                />
                                <path
                                    className={classes.succcesCheckCheck}
                                    fill="none"
                                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                />
                            </svg>
                        </IconWrapper>
                        <h1
                            className={classes.cardTitle}
                        >{`Sikeres rendelés`}</h1>
                    </PDetailsTitle>
                    <PDetailsBody>
                        <DetailsTitle>Köszönjük rendelésed!</DetailsTitle>
                        <p style={{ marginTop: "-1rem", fontSize: "0.8rem" }}>
                            Rendelési azonosító: {order?.id}
                        </p>
                        <DetailsMessage style={{ marginBottom: "0.5rem" }}>
                            A rendelésed sikeresen rögzítettük a rendszerünkben.
                            Mivel a banki átutalásos fizetési módot
                            választottad, így a fizetést követően kerül
                            véglegesítésre az rendelésed. Kérjük vedd
                            figyelembe, hogy amennyiben az összeg nem érkezik be
                            3 munkanapon belül, akkor rendelésed törlésre kerül.
                            Az átutaláshoz szükséges adatok:
                        </DetailsMessage>
                        <IdText>
                            {`Közlemény: ${order?.id}`}
                            <IconButton
                                type="button"
                                aria-label="toggle copy to clipboard"
                                onClick={(e) => {
                                    navigator?.clipboard?.writeText(order?.id);
                                    e.stopPropagation();
                                }}
                            >
                                <MdOutlineContentCopy />
                            </IconButton>
                        </IdText>
                        <IdText>
                            Kedvezményezett neve: Datatronic Kft.{" "}
                            <IconButton
                                type="button"
                                aria-label="toggle copy to clipboard"
                                onClick={(e) => {
                                    navigator?.clipboard?.writeText(
                                        "Datatronic Kft."
                                    );
                                    e.stopPropagation();
                                }}
                            >
                                <MdOutlineContentCopy />
                            </IconButton>
                        </IdText>
                        <IdText>
                            Bankszámlaszám: 10918001-00000058-08820029{" "}
                            <IconButton
                                type="button"
                                aria-label="toggle copy to clipboard"
                                onClick={(e) => {
                                    navigator?.clipboard?.writeText(
                                        "10918001-00000058-08820029"
                                    );
                                    e.stopPropagation();
                                }}
                            >
                                <MdOutlineContentCopy />
                            </IconButton>
                        </IdText>
                        <IdText>
                            {`Összeg: ${order?.totalPrice?.grossAmount}Ft`}
                            <IconButton
                                type="button"
                                aria-label="toggle copy to clipboard"
                                onClick={(e) => {
                                    navigator?.clipboard?.writeText(
                                        order?.totalPrice?.grossAmount
                                    );
                                    e.stopPropagation();
                                }}
                            >
                                <MdOutlineContentCopy />
                            </IconButton>
                        </IdText>
                        <IdText></IdText>
                        <DetailsMessage>
                            A rendelésed részleteiről és az utaláshoz szükséges
                            információkról egy e-mail üzenetet is küldtünk a
                            megadott e-mail címre. Az átutalás feldolgozását
                            követően visszaigazoló e-mailben fogunk tájékoztatni
                            a sikeres rendelésről.
                        </DetailsMessage>
                    </PDetailsBody>
                    <HomeBtn href="/">Vissza a főoldalra</HomeBtn>
                </div>
            </Grid>
        </Fragment>
    );
};

export default TransferDetails;
