
import settlements from "constants/settlements.json"
import postalCodes from "constants/postalCodes.json"
import settlementNames from "constants/settlementNames.json"


export const usePostalCodeFields = () => {

    const calcAddress = (key) => {
        if (parseInt(key) > 1000 && parseInt(key) < 1300) {
            return "Budapest"
        } else {
            return postalCodes[key]
        }
    }

    const calcPostalCode = (key) => {
        if (key === "Budapest") {
            return key
        } else {
            return settlements[key]
        }
    }

    const calcAddressSuggestions = (letters) => {
        if (letters.length < 3) return []
        return settlementNames.filter(name => name.includes(letters))
    }


    return {
        calcAddress,
        calcPostalCode,
        calcAddressSuggestions
    }
}