import { IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CardMedia from "@material-ui/core/CardMedia";
import { useTheme } from "@material-ui/core";
import ItemCounter from "./ItemCounter/ItemCounter";
import {
  useStyles,
  ItemWrapper,
  ItemData,
  SubTotal,
  DeleteBtnWrapper,
  ItemCountWrapper,
} from "./styles";
import Tooltip from '@mui/material/Tooltip';

const CartItem = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  
  return (
    <ItemWrapper>
      <CardMedia
        className={classes.img}
        component="img"
        image={props.thumbnail}
        title="Thumbnail"
      />
      <ItemData className={classes.itemDataOuter}>
        {/* <Typography noWrap className={classes.itemName} component="body1">
          {props.name}
        </Typography> */}
        <Typography
          noWrap
          component={"span"}
          className={classes.itemData}
          style={{
            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
          }}
        >
          <Tooltip title={props.id.split(" ").splice(1).join(" ")}><div>{props.id.split(" ").splice(1).join(" ")}</div></Tooltip>
        </Typography>
        <Typography
          style={{
            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
          }}
        >
          {props.size}
        </Typography>
      </ItemData>
      <ItemCountWrapper>
        <ItemCounter
          category={props.category}
          count={props.count}
          decrement={props.decrement}
          increment={props.increment}
          style={{
            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
          }}
        />
      </ItemCountWrapper>
      <SubTotal>
        <Typography
          className={classes.itemData}
          style={{
            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
          }}
        >
          {props.total} HUF
        </Typography>
      </SubTotal>
      <DeleteBtnWrapper>
        <IconButton className={classes.deleteBtn} onClick={props.delete}>
          <DeleteIcon />
        </IconButton>
      </DeleteBtnWrapper>
    </ItemWrapper>
  );
};

export default CartItem;
