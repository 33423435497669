/** @format */

import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import background from 'assets/images/test.jpg';
import { DataGrid, DataWrapper, MethodLogo, useStyles } from './styles';
import { Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import { successToast, errorToast } from 'components/Toast/Toast';

const Review = (props) => {
  const theme = useTheme();

  const cart = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const { getDocuments, downloadDocuments, documents, subscribeNewsletter } = useDocumentsApi();

  const handleOrder = async () => {
    if (newsAccept) {
      let values = getOrderJson().customerContact;
      await subscribeNewsletter({ values }).then((res) => {
        if (res) {
          successToast('Hírlevélre való feliratkozás sikeres');
        } else {
          errorToast('Hírlevélre való feliratkozás sikertelen');
        }
      });
    }
    await props.handleNext({
      order: getOrderJson(),
      shopId: cart?.shopId
    });
  };

  useEffect(() => {
    getDocuments();
  }, [shopId]);

  const getOrderJson = () => {
    const orderjson = {
      cartId: cart?.id,
      vendorKey: 1,
      billingInfo: {
        customerName: `${props.billingData?.firstName} ${props.billingData?.lastName}`,
        companyName: props.billingData?.companyName,
        vatNumber: props.billingData?.VATNumber,
        address: {
          country: props.billingData?.country,
          zipCode: props.billingData?.zip,
          city: props.billingData?.city,
          street: props.billingData?.street,
          streetNo: ''
        }
      },
      address: {
        country: props.billingData?.country,
        zipCode: props.billingData?.zip,
        city: props.billingData?.city,
        street: props.billingData?.street,
        streetNo: ''
      },
      customerContact: {
        firstName: props.billingData?.firstName,
        lastName: props.billingData?.lastName,
        email: props.billingData?.email,
        phone: props.billingData.phone.startsWith('06', 0) ? props.billingData.phone : `+36${props.billingData?.phone}`
      },
      description: '',
      // FOR DELIVERY
      // deliveryMethod: props?.deliveryMethod,
      deliveryTarget: {
        deliveryTarget: props?.deliveryTarget
      },
      invoiceVendorType: 'SZAMLAZZ_HU',
      paymentMethod: props?.paymentMethod,
      status: 'PENDING',
      language: 'HU'
    };

    return orderjson;
  };

  const classes = useStyles();

  const billingAddress = [
    props.billingData.country,
    props.billingData.zip,
    props.billingData.city,
    props.billingData.street
  ];

  const [newsAccept, setNewsAccept] = useState(false);
  const [tocAccepted, setTocAccepted] = useState(false);
  const [aszfAccepted, setAszfAccepted] = useState(false);

  // const checkDocumentAcceptance = () => {
  //   if (documents?.find((d) => d.accepted === true)) {
  //     setAszfAccepted(true);
  //   } else {
  //     setAszfAccepted(false);
  //   }
  // };

  // useEffect(() => {
  //   checkDocumentAcceptance();
  // }, [documents]);

  return (
    <Fragment>
      <Typography
        variant='h6'
        gutterBottom
        style={{
          fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`
        }}>
        Termékek
      </Typography>
      <List disablePadding>
        {cart.items.map((item, idx) => (
          <ListItem className={classes.listItem} key={idx}>
            <img alt={item.name} src={item.thumbnailURL || background} width='90px' />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Typography className={classes.listItemText} variant='body2'>
                {item.modifiersText.split(' ').slice(1).join(' ')}
              </Typography>
              <Typography className={classes.listItemText} variant='body2'>
                {item.quantity + ' db'}
              </Typography>
            </div>

            <Typography className={classes.typographyPrice} variant='body2'>
              {item.unitPrice.grossAmount.toFixed(0)} HUF
            </Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          {/* <ListItemText
            primary="Teljes összeg"
            style={{ fontFamily: theme.typography.main }}
          /> */}
          <Typography variant='subtitle1' className={classes.totalText}>
            Teljes összeg
          </Typography>
          <Typography variant='subtitle1' className={classes.total}>
            {cart.totalPrice.grossAmount.toFixed(0)} HUF
          </Typography>
        </ListItem>
      </List>
      <DataGrid>
        <DataWrapper>
          <Typography variant='h6' gutterBottom className={classes.title}>
            Kapcsolattartói Adatok
          </Typography>
          <Typography gutterBottom className={classes.data}>
            {props.billingData.firstName + ' ' + props.billingData.lastName}
          </Typography>
          <Typography gutterBottom className={classes.data}>
            {props.billingData.email}
          </Typography>
          <Typography gutterBottom className={classes.data}>{`+36 ${props.billingData.phone}`}</Typography>
        </DataWrapper>
        <DataWrapper>
          <Typography variant='h6' gutterBottom className={classes.title}>
            Számlázási Adatok
          </Typography>
          {props.isCompany ? (
            <>
              <Typography gutterBottom className={classes.data}>
                {props.billingData.companyName}
              </Typography>
              <Typography gutterBottom className={classes.data}>
                {props.billingData.VATNumber}
              </Typography>
            </>
          ) : (
            <Typography gutterBottom className={classes.data}>
              {props.billingData.firstName + ' ' + props.billingData.lastName}
            </Typography>
          )}

          <Typography gutterBottom className={classes.data}>
            {billingAddress.join(', ')}
          </Typography>
        </DataWrapper>

        <DataWrapper>
          <Typography variant='h6' gutterBottom className={classes.title}>
            Szállítási Adatok
          </Typography>
          {/*
          Currently there is only one delivery option what we use, so this is't necessary.
          <Typography gutterBottom className={classes.data}>
            {
              props.deliveryMethods.filter(
                (method) => method.key === props.deliveryMethod
              )[0].name
            }
            {
              props.deliveryMethods.filter(
                (method) => method.key === props.deliveryMethod
              )[0].logo
            }
          </Typography> */}

          <Typography className={classes.data}>{props?.deliveryTarget}</Typography>
        </DataWrapper>
        <DataWrapper
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant='h6' gutterBottom className={classes.title}>
            Fizetés módja
          </Typography>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              width: '80%',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Typography gutterBottom className={classes.data} style={{ marginBottom: '0', marginRight: '0.5rem' }}>
              {props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].name}
            </Typography>
            <MethodLogo
              src={props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].logo}
              alt={props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].name}
              url={props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].url}
            />
          </div>
        </DataWrapper>
      </DataGrid>
      <Grid>
        <div style={{ marginTop: '0.5rem' }}>
          <div>
            Szeretnék a továbbiakban is értesülni a rendszert érintő újdonságokról, iskolai fotózással kapcsolatos
            hírekről.
          </div>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setNewsAccept(e.target.checked);
                }}
                error={!newsAccept}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={classes.data}>
                <p>Feliratkozom a hírlevélre</p>
              </div>
            }
          />
        </div>
        {props.paymentMethod !== 'TRANSFER' && (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setTocAccepted(e.target.checked);
                }}
                error={!tocAccepted}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={classes.data}>
                <p>
                  A{' '}
                  {/* {
                                    props?.paymentMethods?.filter(
                                        (method) =>
                                            method.key === props.paymentMethod
                                    )[0].name
                                }{" "} */}
                  SimplePay{' '}
                  <a
                    target={'_blank'}
                    rel='noreferrer'
                    href={
                      props.paymentMethod === 'BARION'
                        ? 'https://www.barion.com/hu/altalanos-szerzodesi-feltetelek/'
                        : 'https://simplepay.hu/wp-content/uploads/2021/09/SimplePay_b2c_aff_hun_eng_20211001.pdf'
                    }>
                    Általános Felhasználási Feltételeit
                  </a>{' '}
                  elfogadom.
                </p>
              </div>
            }
          />
        )}

        {!!documents?.find((d) => d?.name?.includes('ajándék')) ? (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setAszfAccepted(!aszfAccepted);
                }}
                error={!aszfAccepted}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={classes.data}>
                <p>
                  Ajándék tárgyak vásárlása esetén az alábbi
                  {documents
                    ?.filter((d) => d?.name?.includes('ajándék'))
                    ?.map((d) => {
                      return (
                        <div>
                          <a
                            href='/'
                            style={{
                              textDecoration: 'underline',
                              color: 'blue'
                            }}
                            rel='noreferrer'
                            onClick={(event) => {
                              event.preventDefault();
                              downloadDocuments(d.id, d.name);
                            }}>
                            {d.name}{' '}
                          </a>
                          {d.id === 1}
                        </div>
                      );
                    })}
                  dokumentumokban foglaltakat elfogadom. <br />
                  Nyomtatott és digitális képek vásárlása esetén az alábbi
                  {documents
                    ?.filter((d) => !d?.name?.includes('ajándék'))
                    ?.map((d) => {
                      return (
                        <div>
                          <a
                            href='/'
                            style={{
                              textDecoration: 'underline',
                              color: 'blue'
                            }}
                            rel='noreferrer'
                            onClick={(event) => {
                              event.preventDefault();
                              downloadDocuments(d.id, d.name);
                            }}>
                            {d.name}{' '}
                          </a>
                          {d.id === 1}
                        </div>
                      );
                    })}
                  dokumentumokban foglaltakat elfogadom.
                </p>
              </div>
            }
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setAszfAccepted(!aszfAccepted);
                }}
                error={!aszfAccepted}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={classes.data}>
                <span>
                  Az alábbi
                  {documents?.map((d, idx) => {
                    return (
                      <div key={idx}>
                        <a
                          href='/'
                          style={{
                            textDecoration: 'underline',
                            color: 'blue'
                          }}
                          rel='noreferrer'
                          onClick={(event) => {
                            event.preventDefault();
                            downloadDocuments(d.id, d.name);
                          }}>
                          {d.name}{' '}
                        </a>
                        {d.id === 1}
                      </div>
                    );
                  })}
                  dokumentumokban foglaltakat elfogadom.
                </span>
              </div>
            }
          />
        )}
      </Grid>
      {tocAccepted && aszfAccepted && props.paymentMethod !== 'TRANSFER' && (
        <Grid>
          <p style={{ color: theme.palette.primary.error }}>
            <b>Figyelmeztetés!</b> A "Fizetés" gombra kattintva a rendszer át fog irányítani a{' '}
            {props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].name} oldalára. Amennyiben
            ez mégsem történne meg, az általad megadott email címre kiküldünk egy a fizetéshez szükséges linket
            tartalmazó levelet, amely segítségével végrehajthatod a fizetést. Link hiányában kérjük, hogy próbáld meg
            újra leadni a rendelésed.
          </p>
        </Grid>
      )}

      <Grid item container justifyContent='space-between' direction='row' spacing={3}>
        {props.stepNumber !== 0 && (
          <Grid item xs={6} md={3}>
            <Button
              style={{
                border: '1px solid rgba(0,0,0,0.2)',
                margin: '30px 0 0 25px '
              }}
              onClick={props.handleBack}>
              Vissza
            </Button>
          </Grid>
        )}
        <Grid item container xs={6} md={3} justifyContent='center'>
          <Button
            className={classes.button}
            disabled={
              props.paymentMethod === 'TRANSFER'
                ? !aszfAccepted || props.submitted
                : !tocAccepted || !aszfAccepted || props.submitted
            }
            variant='contained'
            color='primary'
            onClick={handleOrder}
            type='submit'>
            Fizetés
          </Button>
        </Grid>
      </Grid>
      <label hidden={!props.submitted && !props.loading}>
        <CircularProgress />
      </label>
    </Fragment>
  );
};

export default Review;
